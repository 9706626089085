import util from '../utils.js'
import api from '../../api/index.js'
import Storage from '../Storage.js'
import { Message } from 'element-ui';
import OSS from 'ali-oss';
import Base64 from './base64.js'

function getSecurityTokenWithSign(token) {
    return new Promise(resolve => {
        const that = this;
        let webUploadSignStorage = Storage.getStorage('webUploadSign');
        let needUpdate = false;
        if (webUploadSignStorage) {
            let expiration = webUploadSignStorage.expiration;
            let now = Date.parse(new Date());
            if (expiration < now + 3000) {
                needUpdate = true;
            } else {
                needUpdate = false;
            }
        } else {
            needUpdate = true;
        }
        if (needUpdate) {
            //osskey过期
            var accessToken = token;
            if (!accessToken) {
                accessToken = Storage.getStorage('userInfo').token;
            }
            let param = {
                token: accessToken,
                appSource: 8
            }
            api.getSecurityTokenWithSign(param).then(res => {
                resolve(res);
            })

        } else {
            let result = {
                body: {
                    webUploadSign: webUploadSignStorage
                },
                success: true
            }
            resolve(result);
        }

    })
}
const uploadFile = async function(params) {
    let v = await getSecurityTokenWithSign(params.token);
    if (v.success) {
        let webUploadSign = v.body.webUploadSign;
        Storage.setStorage('webUploadSign', webUploadSign);
        if (!params.filePath || params.filePath.length < 9) {
            uni.showModal({
                title: '图片错误',
                content: '请重试',
                showCancel: false,
            })
            return;
        }
        if (params.ty == 'image') {
            var aliyunFileKey = params.dir + util.uuid() + '.png';

        } else if (params.ty == 'video') {
            var aliyunFileKey = params.dir + util.uuid() + '.mp4';
        } else if (params.ty == 'audio') {
            var aliyunFileKey = params.dir + util.uuid() + '.mp3';
        }
        console.log(params.filePath, 'params.filePath')
        let client = new OSS({
            'accessKeyId': webUploadSign.accessKeyId,
            'accessKeySecret': webUploadSign.accessKeySecret,
            'bucket': webUploadSign.bucketName,
            'stsToken': webUploadSign.securityToken,
            'region': 'oss-cn-beijing'
        })
        let callbackObj = JSON.parse(Base64.decode(webUploadSign.callback));
        let result = await client.multipartUpload(aliyunFileKey, params.filePath, {
            progress: async function(p) {
                let e = {}
                e.percent = p * 100
                params.onProgress(e)
            },
            callback: {
                url: callbackObj.callbackUrl,
                body: callbackObj.callbackBody,
                contentType: callbackObj.callbackBodyType,
            }
        })
        if (result.res.statusCode === 200) {
            params.success(result);
            console.log(result);
        } else {
            params.fail(result)
        }
        // var uploadTask = uni.uploadFile({
        //     url: webUploadSign.host,
        //     filePath: params.filePath,
        //     name: 'file',
        //     fileType: params.ty,
        //     formData: {
        //         'OSSAccessKeyId': webUploadSign.accessKeyId,
        //         'bucketName': webUploadSign.bucketName,
        //         'callback': webUploadSign.callback,
        //         'policy': webUploadSign.policy,
        //         'x-oss-security-token': webUploadSign.securityToken,
        //         'signature': webUploadSign.signature,
        //         'key': aliyunFileKey,
        //         'success_action_status': '200',
        //     },
        //     success: function(res) {
        //         if (res.statusCode != 200) {
        //             if (params.fail) {
        //                 params.fail(res)
        //             }
        //             return;
        //         }
        //         if (params.success) {
        //             params.success(aliyunFileKey);
        //         }
        //     },
        //     fail: function(err) {
        //         console.log(err)
        //         err.wxaddinfo = webUploadSign.host;
        //         if (params.fail) {
        //             params.fail(err)
        //         }
        //     }
        // })
        // uploadTask.onProgressUpdate(function (res, res2) {
        //     params.progressUpdate(res, res2)
        // });


    } else {
        Message.error(v.msg);
        return;
    }

}
export default uploadFile;