<template>
  <el-container>
    <el-header><pageHeader></pageHeader></el-header>
    <el-container>
      <el-aside width="100px"><pageAside cindex="1"></pageAside></el-aside>
      <el-main id="elmain">
        <div class="container">
          <div class="areaName">{{ type == 1 ? "测评区" : "准备区" }}</div>
          <div class="topBox">
            <div class="topBox1">
              <div class="el-page-header" style="align-items: center">
                <div class="el-page-header__left" @click="goBack">
                  <i class="el-icon-back"></i>
                  <div class="el-page-header__title">返回</div>
                </div>
                <div class="groupNo">
                  第{{ groupNo }}组
                  <el-button
                    type="success"
                    size="small"
                    @click.native.prevent="goBack"
                    class="changeFz"
                    >切换分组</el-button
                  >
                </div>
              </div>

              <div class="stuListBox">
                <div class="topBoxHead">小组成员：</div>
                <div class="fzStuList">
                  <div
                    class="fzStuItem"
                    v-for="(item, index) in examineesList"
                    :key="index"
                  >
                    <div class="picBox">
                      <el-image
                        v-if="item.regPhoto"
                        :src="item.regPhoto"
                        class="stuPic"
                        fit="cover"
                      ></el-image>
                      <el-image
                        v-else-if="item.stuSex == 'male'"
                        :src="require('@/assets/images/male.png')"
                        class="stuPic"
                        fit="cover"
                      ></el-image>
                      <el-image
                        v-else-if="item.stuSex == 'female'"
                        :src="require('@/assets/images/female.png')"
                        class="stuPic"
                        fit="cover"
                      ></el-image>
                      <el-image
                        v-else
                        :src="require('@/assets/images/defaultUserPic.png')"
                        class="stuPic"
                        fit="cover"
                      ></el-image>
                      <div class="shardowBox">
                        <div class="stuNo">{{ item.controlNo }}</div>
                      </div>
                    </div>
                    <div class="stuName">{{ item.stuName }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="topBox2">
              <el-button
                plain
                class="selectExercise"
                v-if="examineesQuestionList.length == 0"
                @click.native.prevent="questionListVis = true"
                >选题</el-button
              >
              <div v-else class="hasExercise">
                <el-button
                  plain
                  class="selectExercise"
                  @click.native.prevent="gotoChooseQues"
                  >重新选题</el-button
                >
                <span class="questionTitle">{{
                  examineesQuestionList[0].question.title
                }}</span>
                <span class="hrefLink" @click="openQues">查看题目详情 ></span>
              </div>
            </div>
          </div>
          <div class="bottomBox">
            <div class="liveBox">
              <div class="boxHead">
                <div class="headCont"><span class="icon1"></span>直播录制</div>
                <div class="recordingTime" v-if="recordingTime != '0'">
                  已录制：{{ recordingTime }}
                </div>
                <div class="selectDeviceBox">
                  <div class="deviceLabel">选择摄像头</div>
                  <el-select
                    v-model="cameraName"
                    placeholder="请选择"
                    @change="cameraChange"
                    size="mini"
                    @focus="getDevice('focus')"
                  >
                    <el-option
                      v-for="item in cameraList"
                      :key="item.deviceId"
                      :label="item.deviceName"
                      :value="item.deviceId"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="selectDeviceBox">
                  <div class="deviceLabel">选择麦克风</div>
                  <el-select
                    v-model="microPhoneName"
                    placeholder="请选择"
                    @change="microPhoneChange"
                    size="mini"
                    @focus="getDevice('focus')"
                  >
                    <el-option
                      v-for="item in microPhoneList"
                      :key="item.deviceId"
                      :label="item.deviceName"
                      :value="item.deviceId"
                    >
                    </el-option>
                  </el-select>
                </div>
                <el-button
                  type="success"
                  size="mini"
                  @click.native.prevent="liveBegin"
                  :loading="pushLoading"
                  :disabled="isPlaying"
                  >{{ isPlaying ? "录制中..." : "开始录制" }}</el-button
                >
                <el-button
                  plain
                  size="mini"
                  @click.native.prevent="liveStop"
                  :disabled="!isPlaying"
                  >结束录制</el-button
                >
              </div>
              <div class="liveCont" id="liveCont" ref="liveCont"></div>
              <div class="uploadedList" v-if="worksList.length > 0">
                <div
                  class="uploadItem"
                  :hidden="item.source != 'live'"
                  v-for="(item, index) in worksList"
                  :key="index"
                >
                  <div class="uploadSuss">
                    <el-image
                      class="cover"
                      :src="require('@/assets/images/defaultVideoBg.png')"
                      fit="cover"
                    ></el-image>
                    <div class="shardowBox">
                      <div
                        class="deleteMedia"
                        @click="deleteMedia(item.fileId)"
                      >
                        <i class="el-icon-delete"></i>
                      </div>
                      <el-image
                        @click="prevVideo(index)"
                        :src="require('@/assets/images/playVideo.png')"
                        class="playVideoIcon"
                      ></el-image>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="uploadBox">
              <div class="boxHead">
                <div class="headCont">
                  <span class="icon2"></span>本地的测评作品上传
                </div>
              </div>
              <el-upload
                class="upload-demo"
                drag
                action=""
                :http-request="fnUploadRequest"
                :show-file-list="false"
                accept=".mp4,.avi,.flv,.MOV,.RM,.MPEG,.rmvb"
                :disabled="isUploading"
              >
                <div class="uploadCont" slot="trigger">
                  <span class="uploadIcon"></span>
                  <span class="uploadText"
                    >将文件拖到此处，或点击上传本地文件</span
                  >
                </div>
              </el-upload>
              <div class="uploadedList" v-if="worksList.length > 0">
                <div
                  class="uploadItem"
                  v-for="(item, index) in worksList"
                  :key="index"
                  :hidden="item.source != 'upload'"
                >
                  <div class="uploading" v-if="item.status == 'uploading'">
                    <el-progress
                      type="circle"
                      :percentage="item.progress"
                      :width="70"
                    ></el-progress>
                  </div>
                  <div
                    class="uploadSuss"
                    v-else-if="item.status == 'upSuccess'"
                  >
                    <el-image
                      class="cover"
                      :src="item.coverPath"
                      fit="cover"
                    ></el-image>
                    <div class="shardowBox">
                      <div
                        class="deleteMedia"
                        @click="deleteMedia(item.fileId)"
                      >
                        <i class="el-icon-delete"></i>
                      </div>
                      <el-image
                        :src="require('@/assets/images/playVideo.png')"
                        class="playVideoIcon"
                        @click="prevVideo(index)"
                      ></el-image>
                    </div>
                  </div>
                  <div class="uploadSuss" v-else>
                    <el-image
                      class="cover"
                      :src="item.coverPath"
                      fit="cover"
                    ></el-image>
                    <div class="shardowBox">上传失败</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
    <el-dialog :title="questionTitle" width="50%" :visible.sync="questionVis"
      ><mixrow
        v-if="examineesQuestionList.length > 0"
        :desc="examineesQuestionList[0].question.content"
      />
    </el-dialog>

    <el-dialog
      title="视频预览"
      width="50%"
      :close-on-click-modal="false"
      :before-close="videoBeforeClose"
      class="videoDialog"
      :visible.sync="videoVis"
    >
      <video-player
        class="video-player-box"
        ref="videoPlayer"
        width="100%"
        :options="playerOptions"
        :playsinline="true"
        customEventName="customstatechangedeventname"
      >
      </video-player>
    </el-dialog>

    <el-drawer
      title="选题"
      :visible.sync="questionListVis"
      size="40%"
      custom-class="demo-drawer"
      :before-close="handleClose"
      destroy-on-close
      :key="drawerKey"
      ref="drawer"
    >
      <div class="demo-drawer__content">
        <div class="questionListBox">
          <div
            class="questionItem"
            v-for="(item, index) in questionList"
            :key="index"
            :class="{ isInvisible: testInfo.moduleName == 'B模块' }"
          >
            <template v-if="testInfo.moduleName == 'A模块'">
              <div class="questionCont">
                {{ index + 1 }}/{{ questionListTotal }} {{ item.title }}
              </div>

              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon="el-icon-info"
                icon-color="red"
                @confirm="chooseQuestion(item.id)"
                title="确定选择此题吗？"
              >
                <div class="selectBtn" slot="reference">选定</div>
              </el-popconfirm>
            </template>
            <template v-else>
              <div class="questionNo">{{ index + 1 }}</div>
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon="el-icon-info"
                icon-color="red"
                @confirm="chooseQuestion(item.id)"
                title="确定选择此题吗？"
              >
                <div class="chooseBtn" slot="reference">选定</div>
              </el-popconfirm>
            </template>
          </div>
        </div>
        <div class="demo-drawer__footer">
          <el-button
            type="primary"
            @click.native.prevent="questionListVis = false"
            >关 闭</el-button
          >
        </div>
      </div>
    </el-drawer>
    <el-drawer
      :title="quesDrawerTitle"
      :visible.sync="questionListVis2"
      size="40%"
      custom-class="demo-drawer2"
      destroy-on-close
      ref="drawer2"
    >
      <div class="quesMixrow" v-if="examineesQuestionList.length > 0">
        <mixrow :desc="examineesQuestionList[0].question.content" />
      </div>
    </el-drawer>
  </el-container>
</template>
<script>
import { mapState } from "vuex";
import uploadFile from "@/utils/upLoadAliyun/upload.js";
import pageHeader from "@/components/pageHeader.vue";
import pageAside from "@/components/pageAside.vue";
import mixrow from "@/components/mixrow.vue";
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";

export default {
  beforeRouteLeave(to, from, next) {
    const that = this;
    if (that.isPlaying) {
      that
        .$confirm("此页面正在推流，离开页面将导致推流断开", "是否离开页面？", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.livePusher.stopPush();
          that.isPlaying = false;
          next();
          that.$notify({
            title: "提示",
            message: "已结束录制",
          });
        })
        .catch(() => {});
    } else {
      next();
    }
  },
  components: {
    pageHeader,
    pageAside,
    mixrow,
    videoPlayer,
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
    ...mapState(["testInfo", "userInfo"]),
  },
  data() {
    return {
      groupNo: "",
      examineesList: [],
      selQuesLoading: false,
      questionTitle: "",
      examineesQuestionList: [],
      myPublisher: null,
      questionList: [],
      questionListTotal: "",
      questionVis: false,
      questionListVis: false,
      questionListVis2: false,
      pushUrl: "",
      publicPath: process.env.BASE_URL,
      pushLoading: false,
      isPlaying: false,
      isFinished: false,
      uploadList: [],
      quesDrawerTitle: "选题",
      worksList: [],
      quality: {
        //标清
        fps: 20,
        bitrate: 800,
        videoWidth: 640,
        videoHeight: 480,
      },
      type: "", //0:准备区，1：测评区
      isUploading: false,
      playerOptions: {
        language: "cn",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],
        poster: "",
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: true, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
      videoVis: false,
      streamId: "",
      drawerKey: "0",
      cameraName: "",
      cameraIndex: 0,
      microPhoneName: "",
      microPhoneIndex: 0,
      recordingTime: 0,
      timer: null,
      isError: false,
      cameraList: [],
      microPhoneList: [],
      deviceManager: null,
      livePusher: null,
    };
  },
  created() {
    this.groupNo = this.$route.query.groupNo;
    this.type = this.$route.query.type;
    this.getTestingDetail();
    this.getQuestionList();
    this.livePusher = new TXLivePusher();
  },
  mounted() {
    const that = this;
    this.initPublisher();
  },
  destroyed() {
    this.livePusher.stopPush();
    // 关闭摄像头
    this.livePusher.stopCamera();
    // 关闭麦克风
    this.livePusher.stopMicrophone();
    this.livePusher.destroy();
  },
  methods: {
    initPublisher() {
      const that = this;
      TXLivePusher.checkSupport().then(function (data) {
        // 是否支持WebRTC
        if (data.isWebRTCSupported) {
          console.log("WebRTC Support");
        } else {
          console.log("WebRTC Not Support");
          that.$message({
            showClose: true,
            message: "该浏览器不支持直播推流，请更换chrome浏览器",
            type: "error",
            duration: 0,
          });
        }
        // 是否支持H264编码
        if (data.isH264EncodeSupported) {
          console.log("H264 Encode Support");
        } else {
          console.log("H264 Encode Not Support");
        }
      });
      // 打开摄像头
      this.livePusher.startCamera();
      // 打开麦克风
      this.livePusher.startMicrophone();
      this.livePusher.setRenderView("liveCont");
      this.$refs.liveCont.children[0].controls = true;
      this.$refs.liveCont.children[0].volume = 0;
      // 设置视频质量
      this.livePusher.setVideoQuality("720p");
      // 设置音频质量
      this.livePusher.setAudioQuality("standard");
      // 自定义设置帧率
      this.livePusher.setProperty("setVideoFPS", 25);

      that.getDevice();

      this.livePusher.setObserver({
        onError: function (e) {
          console.log(e);
        },
        onWarning: function (e) {
          console.log(e);
          if (e == -1001) {
            that.$message.error("未获取到摄像头，请检查摄像头");
            that.isError = true;
          } else if (e == -1002) {
            that.$message.error("未获取到麦克风，请检查麦克风");
            that.isError = true;
          }
        },
        onPushStatusUpdate: function (e) {
          console.log("推流状态：" + e);
          if (e == 1) {
            that.pushLoading = true;
          } else if (e == 2) {
            that.pushLoading = false;
            that.isPlaying = true;
            that.$notify({
              title: "提示",
              message: "推流成功",
            });
            var time = 0;
            that.timer = setInterval(function () {
              that.recordingTime = that.recordTime(time);
              time++;
            }, 1000);
            if (that.examineesQuestionList.length == 0) {
              that.$alert(
                "请在结束录制前选题，否则录制的视频将失效",
                "请选题",
                {
                  confirmButtonText: "确定",
                  callback: (action) => {
                    that.questionListVis = true;
                  },
                }
              );
            }
          }
        },
      });
    },
    getDevice(type) {
      const that = this;
      that.deviceManager = this.livePusher.getDeviceManager();

      // 获取设备列表
      that.deviceManager.getDevicesList("video").then(function (data) {
        that.cameraList = data;
        that.cameraIndex = data.length - 1;
        if (type !== "focus") {
          that.cameraName = data[data.length - 1].deviceId;
          console.log(that.cameraName);
          that.deviceManager.switchCamera(that.cameraName);
        }
      });
      that.deviceManager.getDevicesList("audio").then(function (data) {
        let newList = [];
        for (var i in data) {
          if (
            data[i].deviceName.indexOf("默认 - 麦克风") == -1 &&
            data[i].deviceName.indexOf("通讯 - 麦克风") == -1
          ) {
            newList.push(data[i]);
          }
        }
        that.microPhoneList = newList;
        that.microPhoneIndex = newList.length - 1;
        if (type !== "focus") {
          that.microPhoneName = newList[newList.length - 1].deviceId;
          console.log(that.microPhoneName);
          that.deviceManager.switchMicrophone(that.microPhoneName);
        }
      });
    },
    recordTime(s) {
      var m = parseInt(s / 60);
      var theS = s % 60;
      if (theS < 10) {
        theS = "0" + theS;
      }
      if (m < 10) {
        m = "0" + m;
      }
      return m + ":" + theS;
    },
    liveBegin() {
      const that = this;
      // if(that.isError){
      //   that.$message.error("设备无法获取，请检查设备");
      //   return;
      // }
      if (that.isFinished) {
        that.$alert("该小组已完成测评", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      let param = {
        token: that.userInfo.token,
        sitesId: that.testInfo.sitesId,
      };
      that.pushLoading = true;
      that.$api.getLiveInfo(param).then((res) => {
        if (res.success) {
          let pushUrl = res.body.pushUrl;
          let stream_param = {
            operatorId: that.userInfo.userId,
            worksName: that.type == 1 ? "测评区" : "准备区",
            groupNo: that.groupNo,
          };
          that.pushUrl =
            pushUrl + "&stream_param=" + JSON.stringify(stream_param);
          that.streamId = res.body.streamId;
          that.push();
        } else {
          that.pushLoading = false;
          that.$message(res.msg);
        }
      });
    },
    getTestingDetail() {
      const that = this;
      let param = {
        token: that.userInfo.token,
        sitesId: that.testInfo.sitesId,
        groupNo: that.groupNo,
      };
      that.examineesQuestionList = [];
      that.$api.getTestingDetail(param).then((res) => {
        if (res.success) {
          let examineesList = res.body.examineesList;
          let examineesQuestionList = res.body.examineesQuestionList;
          that.examineesList = examineesList;

          if (examineesQuestionList.length > 0) {
            that.questionTitle = examineesQuestionList[0].question.title;
            that.isFinished = examineesQuestionList[0].isFinished;
            let worksList = examineesQuestionList[0].worksList;
            worksList.forEach((e) => {
              e.coverPath =
                e.source == "upload"
                  ? e.url + that.$utils.common.ossVideoCoverSuffix
                  : "";
              e.status = "upSuccess";
            });
            that.worksList = worksList;
            that.quesDrawerTitle = examineesQuestionList[0].question.title;
          }
          that.examineesQuestionList = examineesQuestionList;
        } else {
          that.$message(res.msg);
        }
      });
    },
    getQuestionList() {
      const that = this;
      let param = {
        token: that.userInfo.token,
        sitesId: that.testInfo.sitesId,
      };
      that.$api.getQuestionList(param).then((res) => {
        if (res.success) {
          that.questionList = res.body.list;
          that.questionListTotal = res.body.total;
        } else {
          that.$message(res.msg);
        }
      });
    },
    gotoChooseQues() {
      const that = this;
      if (that.worksList.length > 0) {
        that.$alert("重新选题需先删除所有作品");
      } else {
        that.questionListVis = true;
        that.hasChooseQuestion = false;
      }
    },
    chooseQuestion(id) {
      const that = this;
      let param = {
        token: that.userInfo.token,
        sitesId: that.testInfo.sitesId,
        questionId: id,
        groupNo: that.groupNo,
      };
      const loading = that.$loading({
        lock: true,
        target: ".demo-drawer",
      });
      that.$api.chooseQuestion(param).then((res) => {
        if (res.success) {
          that.getTestingDetail();
          that.questionListVis = false;
          that.questionListVis2 = true;
        } else {
          that.$message(res.msg);
        }
        setTimeout(() => {
          loading.close();
        }, 1000);
      });
    },
    deleteMedia(id) {
      const that = this;
      let fileIdArray = [];
      fileIdArray.push(id);
      that
        .$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          let param = {
            token: that.userInfo.token,
            sitesId: that.testInfo.sitesId,
            fileIdArray: fileIdArray,
          };
          that.$api.deleteWorks(param).then((res) => {
            if (res.success) {
              that.getTestingDetail();
              that.$message({
                type: "success",
                message: "删除成功!",
              });
            } else {
              that.$message(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    openQues() {
      this.questionListVis2 = true;
    },
    goBack() {
      this.$router.back();
    },
    push() {
      let pushUrl = this.pushUrl.replace("rtmp://", "webrtc://");
      console.log(pushUrl);
      this.livePusher.startPush(pushUrl);
    },
    checkPush() {
      const that = this;
      var i = 0;
      var handler = setInterval(function () {
        let param = {
          token: that.userInfo.token,
          sitesId: that.testInfo.sitesId,
          streamId: that.streamId,
        };
        that.$api.getLiveInfo(param).then((res) => {
          if (res.success) {
            let status = res.body.status;
            if (status == "pushing") {
              clearInterval(handler);
              that.isPlaying = true;
              that.pushLoading = false;
              that.$notify({
                title: "提示",
                message: "推流成功",
              });
              var time = 0;
              that.timer = setInterval(function () {
                that.recordingTime = that.recordTime(time);
                time++;
              }, 1000);
              if (that.examineesQuestionList.length == 0) {
                that.$alert(
                  "请在结束录制前选题，否则录制的视频将失效",
                  "请选题",
                  {
                    confirmButtonText: "确定",
                    callback: (action) => {
                      that.questionListVis = true;
                    },
                  }
                );
              }
            }
            i++;
            if (i == 3 && status != "pushing") {
              clearInterval(handler);
              that.$message("推流失败，请刷新页面重新推流");
              that.isPlaying = false;
              that.pushLoading = false;
            }
          } else {
          }
        });
      }, 3000);
    },
    liveStop() {
      const that = this;
      that
        .$confirm("确定结束考试吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.livePusher.stopPush();
          clearInterval(that.timer);
          that.recordingTime = 0;
          that.isPlaying = false;
          that.$notify({
            title: "提示",
            message: "已结束录制，回放生成需一定时间，如需查看请刷新页面",
            duration: 10000,
          });
        })
        .catch(() => {});
    },
    fnUploadRequest(options) {
      const that = this;
      if (that.isFinished) {
        that.$alert("该小组已完成测评", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if (that.examineesQuestionList.length == 0) {
        that.$alert("请先选题", "提示", {
          confirmButtonText: "确定",
          callback: (action) => {
            that.questionListVis = true;
          },
        });
        return;
      }
      let file = options.file;
      let uploadItemObj = {
        url: "",
        status: "uploading",
        progress: 0,
        source: "upload",
      };
      that.worksList.push(uploadItemObj);
      const loading = that.$loading({
        lock: true,
        target: ".upload-demo",
        text: "上传中...",
      });
      that.isUploading = true;

      this.$nextTick(() => {
        let container = this.$el.querySelector("#elmain");
        container.scrollTop = container.scrollHeight;
      });
      let up = function (obj) {
        uploadFile({
          filePath: file,
          dir: "assessment/examinee_works/",
          ty: "video",
          success: function (res) {
            that.addWorks(res.name);
            obj.url = that.$utils.common.ossUrl + res.name;
            obj.status = "upSuccess";
            obj.coverPath =
              that.$utils.common.ossUrl +
              res.name +
              that.$utils.common.ossVideoCoverSuffix;
            loading.close();
            that.isUploading = false;
          },
          fail: function (res) {
            console.log(res);
            that.$message("上传失败");
            loading.close();
            that.isUploading = false;
          },
          onProgress: function (res) {
            obj.progress = parseInt(res.percent);
          },
        });
      };
      up(uploadItemObj);
    },
    addWorks(url) {
      const that = this;
      let worksFileId = url.substring(url.indexOf("s/") + 2, url.indexOf("."));
      let param = {
        token: that.userInfo.token,
        sitesId: that.testInfo.sitesId,
        groupNo: that.groupNo,
        worksName: that.type == 1 ? "测评区" : "准备区",
        worksFileId: worksFileId,
        worksFileUrl: url,
      };
      that.$api.addWorks(param).then((res) => {
        if (res.success) {
          that.$notify({
            title: "提示",
            message: "上传成功",
          });
          that.getTestingDetail();
        } else {
          that.$message(res.msg);
        }
      });
    },
    prevVideo(index) {
      const that = this;
      let url = that.worksList[index].url;
      let coverPath = that.worksList[index].coverPath;
      that.playerOptions.sources[0].src = url;
      that.playerOptions.poster = coverPath;
      that.videoVis = true;
      setTimeout(() => {
        that.player.play();
      }, 400);
    },
    handleClose() {
      const that = this;

      that.questionListVis = false;
      that.drawerKey++;
    },
    videoBeforeClose() {
      this.player.pause();
      this.videoVis = false;
    },
    cameraChange(e) {
      this.deviceManager.switchCamera(e);
    },
    microPhoneChange(e) {
      this.deviceManager.switchMicrophone(e);
    },
  },
};
</script>
<style >
#app {
  display: flex;
}
.el-header {
  background-color: #2797ff;
  height: 85px !important;
}

.el-aside {
  background-color: #2797ff;
}

.el-main {
  background-color: #0e8bff;
  color: #ffffff;
}

body > .el-container {
  margin-bottom: 40px;
}
.el-container {
  overflow: hidden;
}
.el-page-header__content {
  color: #ffffff;
}
.el-drawer__body {
  display: flex;
  overflow: hidden;
}
.el-drawer__header {
  margin-bottom: 10px;
}
.el-upload {
  flex: 1;
  width: 100%;
}
.el-upload-dragger {
  width: 100%;
  height: 100%;
  display: flex;
  background: inherit;
}
.el-progress__text {
  color: #ffffff;
}
.el-drawer__header span {
  font-size: 18px;
  font-weight: 700;
}
</style>
<style lang="scss" scoped>
.selectBox {
  margin-right: 10px;
  .selctCam {
    margin-right: 10px;
  }
}
.topBox {
  background-color: rgba(0, 0, 0, 0.08);
  border: 1px solid #4ba8e3;
  .topBox1 {
    padding: 20px;
    border-bottom: 1px solid #4ba8e3;
  }
  .groupNo {
    font-size: 18px;
    display: flex;
    align-items: center;
    .changeFz {
      margin-left: 20px;
    }
  }
  .stuListBox {
    margin-top: 30px;
  }
}
.topBox2 {
  padding: 20px;
  .selectExercise {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    &:hover {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
  .hasExercise {
    display: flex;
    align-items: center;
    .questionTitle {
      margin: 0 20px;
      font-size: 16px;
    }
    .hrefLink {
      color: #333;
      cursor: pointer;
    }
  }
}
.fzStuList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  .fzStuItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
    .picBox {
      width: 60px;
      height: 60px;
      border: 1px solid #dcdcdc;
      border-radius: 50%;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      .stuPic {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 1;
      }
      .shardowBox {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        background-color: rgba(0, 0, 0, 0.6);
        .stuNo {
          color: #ffffff;
          font-size: 22px;
          font-weight: 700;
          line-height: 24px;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .changeFz {
          width: 100%;
          height: 50%;
          color: #ffffff;
          background-color: #14d19e;
          font-size: 12px;
          padding-top: 5px;
          text-align: center;
          transition: all linear 0.3s;
          display: none;
        }
      }
    }
    .stuName {
      font-size: 14px;
      margin-top: 10px;
      color: #ffffff;
    }
  }
  .addBtn {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      width: 30px;
      height: 3px;
      border-radius: 2px;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      background-color: #ffffff;
    }
    &::after {
      content: "";
      position: absolute;
      height: 30px;
      width: 3px;
      border-radius: 2px;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      background-color: #ffffff;
    }
  }
}
.questionListBox {
  padding: 10px 30px;
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  .questionItem {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 20px;
    height: max-content;
    &.isInvisible {
      width: 106px;
      height: 151px;
      padding: 0;
      background-color: #2998ff;
      border-radius: 5px;
      margin-right: 10px;
      margin-left: 10px;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHIAAAClCAMAAAC3OFnYAAAAYFBMVEUAAABot/9ot/9ot/9ot/9ot/9ot/9ot/9ot/9ot/9ot/9ot/9ot/9ot/9ot/9ot/9ot/9ot/9ot/9ot/9ot/9ot/8pmP8nkvVot/9Urf9Yr/8+ov88of9nt/9Urf5Tq/zy4qQ3AAAAFnRSTlMABSAMyb4T22sb5dcxgfnj03NYgDOC2efAggAAAa5JREFUeNrt3EFvgkAQBeBhV0AURNTaQW37//9lp+rG6Sbc+jY9vJcQMqcXAh+3HXkk9J2A0/XhNcTjqKrtVAks1dRaxXiMzyfc6iOrRkBpVs+Kbbg/ozWmTtBzVtaYOqPNg76yFkjWrmKweePmvUCydxUbkaA+UQCJ6hOk18LppdPC6URaN17Olne7/vZ+cRVt9jldIY3na4aiqjVlhjRaZk2p7/SbGtxo+UyN6Qd3qHdWeEM0pvvN+nb1wf+SVAFN/q7aZL9TVWyjVYrklcjGhUpg41IlrnGxEtq4XAl8nwuVwMaFpwS7FKFLuqRLuqRLuqRLuqRLuqRLuqRLuqRLuqRLuqRLuqRLuqRLuqRLuqRLuqRLuqRLuqRLuqRLuqRLuqRLuqRLuqRLuqRLuqRLuqRLuqRLuqRLuqRLuvzHLpvCLrOj2On0N7BxdgfO/Rn3L3Bj2qjh11/MoMZ8eQB+X0G2IiHfygB5nx/q0pXfPdGX37AR8HtE8tUlBbalZF/oW5GdMN5hG22OLXTzTb5sp9R+H6mm8ec9DulbicPG5nGqBJnuFH7N4dRJqXwDqG04Zi5Q1SwAAAAASUVORK5CYII=);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 104px 149px;
      border: 1px solid #eeeeed;

      .questionNo {
        font-size: 44px;
        font-family: Impact Regular, Impact Regular-Regular;
        font-weight: 400;
        text-align: center;
        color: #fff;
      }
      .chooseBtn {
        display: none;
        width: 66px;
        height: 66px;
        border: 1px solid #8bc3f6;
        border-radius: 50%;
        color: #fff;
        font-size: 18px;
        text-align: center;
        line-height: 66px;
        cursor: pointer;
      }
    }
    &.isInvisible:hover {
      box-shadow: 0 0 12px 2px rgba(133, 133, 133, 0.6);
      .chooseBtn {
        display: block;
      }
      .questionNo {
        display: none;
      }
    }
    .questionCont {
      flex: 1;
      color: #333;
      font-size: 18px;
      font-weight: 700;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .selectBtn {
      margin-left: 20px;
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      font-size: 16px;
      color: #fff;
      background: #2998ff;
      border-radius: 50%;
      cursor: pointer;
      transition: all linear 0.3s;
      &:hover {
        background-color: #007ff5;
      }
    }
  }
}
.bottomBox {
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
}
.liveBox {
  flex: 1;
  margin-right: 30px;
  background-color: rgba(0, 0, 0, 0.08);
  border: 1px solid #4ba8e3;
  .liveCont {
    padding: 5px;
    height: 461px;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    align-content: center;
    justify-content: center;
  }
}
.uploadBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.08);
  border: 1px solid #4ba8e3;
}
.boxHead {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  .headCont {
    flex: 1;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 18px;
    .icon1 {
      width: 28px;
      height: 28px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjEwMzA0NUY4NjEzRjExRUI4RTE0RDI0NDZDNDlBQUFBIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjEwMzA0NUY5NjEzRjExRUI4RTE0RDI0NDZDNDlBQUFBIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTAzMDQ1RjY2MTNGMTFFQjhFMTREMjQ0NkM0OUFBQUEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTAzMDQ1Rjc2MTNGMTFFQjhFMTREMjQ0NkM0OUFBQUEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5GfUsEAAABbklEQVR42mL8//8/Az0B46iFw8pCJiA2B2JxEvSDNN8G4mvE6wBaCMQSQHz+P/lgGRAzQ83Ci2E+XALE0UD8A4hvQV1ODGAGYk0onQnEM5DkuIHYA4jfAvFBuJlQm59DXepHjCvRcD5U7yokMSsgvocUAvNgckxQ13BC6UdkpIOHUJoNSqsD8QEgVgTi31CxeCBmhSUUaoNPQPwUiHcBcRBSggQFMQMLDSx8DvUdCBigSzIx0BlQw8J/UFoViEXpYeFxIP4AxFpAfAmI3Wht4Wsgtgfi60AsAcQ7gbgHKdXSJA5BPjMG4plQfjEQn4BmEaxF2wdoBjUgI+Oj4wAgfgs17y+U/g3ELMgZnxJgAsT3gDgHyt8AxHpAvB8pBPuA+A+yD59BXRJEho/6oXpXoomDymkLINbDVngvgBY/oKLoGYnZShap+FpEbPUkAsTHKaiepkF9RHT1BK6MoUURPxmF9/3RNs2ohTQDAAEGANykKCG2rEGhAAAAAElFTkSuQmCC)
        no-repeat;
      background-size: cover;
      margin-right: 10px;
    }
    .icon2 {
      width: 28px;
      height: 28px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjExQjM5MDQ0NjEzRjExRUI4RUY4OTYxNjU3RjBEOEE4IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjExQjM5MDQ1NjEzRjExRUI4RUY4OTYxNjU3RjBEOEE4Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTFCMzkwNDI2MTNGMTFFQjhFRjg5NjE2NTdGMEQ4QTgiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTFCMzkwNDM2MTNGMTFFQjhFRjg5NjE2NTdGMEQ4QTgiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7NghGtAAACFUlEQVR42uzWzUsVURjHcV+uSl4S04WKwaVFIK2swNwo4sKd+IJIf8FdVItceEEMITARtJ24bePGjSiIiNBOF2IhvhBSEV0zEXwhqHxJc/oe+g1NF2fOiJc2eeDD3Jl5znnmvMycm+k4Tsa/LJmXCdNdss4ZX4kX+IjvWMcIqkO3YHoY0lOcOr/LW7zEG+dPeY4sWzthkyXU6AqqUu5VYkH3+9KRMIYj9arQJyaKJZzg1kUT9ujpmy1x9Z6hvVDCKfxAniXOzN8XzAXF+b0WMbSgAq3IRnGINfgBRRjFO4zjfdAqjWBQPfKW1ZCLazalnpnTYe/oeINNb8cUaBZAG8q0ULLPaLzQZ1jN9RLN+bzam1Fn/kr4QDcnkGvpyYBiE5Y4k2REsV3ehKZ3SeygwNJIf8qwxS3xV7COXeS4FytUechSuVdxmzpu46eGP6hen+Kr3W/pdR3XLKuwHa8xrPMO7KLRUs9ttzyiH191LLJUvI0jPNL5Bm7oWlC55uZxe7iMQzSZLSugotkhTkJcSy3NOMUrN+GBtpk7iKd5C7yPOoxhzzuxpdjQIujW6vJbBI+1COoCYsyr1aGPyI42gYyI50m20IBJ9CKBBXzTfTPknfh0Ri9u4hlydR7FXc3dZ01V0m8Dzken9rh9z/t2jBrFPNS1ezpv0Mi45QCLeIKrYT7etmJ6UItp88yX/9r+r4S/BBgA+seJpMCSYTwAAAAASUVORK5CYII=)
        no-repeat;
      background-size: cover;
      margin-right: 10px;
    }
  }
}
.uploadCont {
  cursor: pointer;
  width: 100%;
  height: 461px;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .uploadIcon {
    width: 107px;
    height: 86px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGsAAABWCAMAAAD/hZhNAAAAmVBMVEX///8AAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////9EnpBsAAAAM3RSTlPyANWJLu7oCQbjGyHq0noC3rujnGRAK82XjU3IwpJdVzW1sKmAdW9SJhQO2Eg7EeVpMtdvlbMTAAAC8UlEQVRo3r3Y53KjMBSG4e+sLcBU27iBey+JW+7/4pZsiuMsoCNR3t/MPDM6siwAqWXvG+txywWMdifcnk1SSMka+EM8J1azC5VvWfsO0nKng7KteIjMuk6ZlrNEXmJjlWb1DEgaN8uxzBDy3H0Z1nECVn5x6zgGs01R6zQGO7+YZa2g0KyQ1YBKYlHAeoVanqltndpQbKptNaDcXdM6CHWro2mNoFFfy7KFjrXSsnxoddCxPD3L17AW0GuoYW2hmaNuLXWtmbIVGLrWVNmyoVtH1brstK22imXGIw8FCvcnprUYSSclIMkN+wyr34E07zCGtEkc5FsDhoQXh44eGNo9x7I2gjP6AxHZL2AUmVmWMwGja/Pj4TYYDQfpVr8FRsb5a7l5j8dp1lyAkeg/9qsBTr3/rTlYVEyP+i443X5bsQCn+fONToDT7NlqKq2Hxro/rMsfFrWl3/XAqW3/sLra7yE7cFoG39YenKICF6Del2WyzoAwoNQiMGodP60tGK2tQjfWxod1MTgrnklRwJm2sBOLN96JWfR9sPFuBYz9Pj5RXmaHMTEzsfqQ5h0pv9OEc+SAppD1YpOs4xCyuonVhqS2Q/JsD5IMEwfpQg+IkyP9kZ4xQ37GgngNZAu0Q4Tc3D5xu18lA8MKeYlX4nc2kNcEXi41lwL8/84WWpLjWam90L4u70i1GTTzSb2bHhWRTn7uxDIakV6NbCvzlH/7k9KBnvLTnsneGR2o1CzwbWyIUW3WGrvarA0WtVmvsIy6rAtoXZPVIdC8JuuWWKZRiyXsxKJpLVaX3i2nFmvxz6KwBmtJH5bjVm81Py3yK7em9GWZXsXW2+nboqZbrXWmh0W9Sq0t/bRoWqEV0rMVdCuz1tYvi6yunsWnHhYFUSXWyKKH9Wjmlm6JG1GqRYNxydbwTlkWWVujRMvdmJRpJdkjUZYV2kS5VqI1WiVY14ZDJLOSrLjbKmRdw9gkYllJQbMXLb2WULaGq6g3CCi1v8Z8L0x/QieSAAAAAElFTkSuQmCC)
      no-repeat;
    background-size: cover;
  }
  .uploadText {
    color: #fff;
    line-height: 30px;
    margin-top: 10px;
  }
}
.uploadedList {
  width: 100%;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  .uploadItem {
    width: 140px;
    height: 90px;
    margin-right: 10px;
    margin-bottom: 10px;
    .uploading {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .uploadSuss {
      width: 100%;
      height: 100%;
      position: relative;
      .cover {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      .shardowBox {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        .playVideoIcon {
          cursor: pointer;
          width: 50px;
          height: 50px;
        }
        .deleteMedia {
          position: absolute;
          right: 0;
          top: 0;
          width: 20px;
          height: 20px;
          background: rgba(0, 0, 0, 0.4);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .el-icon-delete {
            color: rgba(255, 255, 255, 0.6);
          }
          &:hover {
            background: rgba(0, 0, 0, 0.8);
            .el-icon-delete {
              color: rgba(255, 255, 255, 1);
            }
          }
        }
      }
      .delIconBox {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 30px;
        height: 30px;
      }
    }
  }
}
.upload-demo {
  cursor: pointer;
  flex: 1;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.videoDialog {
  /deep/ .el-dialog__body {
    padding: 0 !important;
  }
}
.video-player-box {
  height: 500px;
  /deep/ .video-js {
    width: 100%;
    height: 500px;
  }
}
.quesMixrow {
  padding: 20px;
  flex: 1;
  overflow-y: scroll;
}
.areaName {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  width: 72px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 20px;
}
.recordingTime {
  margin-right: 10px;
}
.demo-drawer__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.demo-drawer__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  button {
    width: 30%;
    max-width: 200px;
  }
}
.selectDeviceBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  .deviceLabel {
    margin-right: 5px;
  }
  .el-select {
    width: 130px;
  }
}
</style>