<template>
  <div class="mixrow">
			<div class="mixrowItem" v-for="(item,index) in theDesc" :key="index">
				<div class="text" v-if="item.type=='text'">
                    <div v-html="item.content.text"></div>
				</div>
				<div class="image" v-else-if="item.type=='image'">
                    <el-image  :src="item.content.url" class="mixrowImg" fit='cover'></el-image>
				</div>
				<div class="video" v-else-if="item.type=='video'">
					<!-- <view class="videoThumbBox" v-if="!item.playing" >
						<image :src="item.pic" mode="aspectFill" class="videoThumb"></image>
						<image src="../static/images/playVideo.png" class="playVideo"   @click="playVideo(index)"></image>
					</view> -->
					
					<video class="videoPlayer" :src="item.content.url" :id="'videoPlayer'+index"  controls></video>
				</div>
				<div class="audio" v-else-if="item.type=='audio'">
                    <aplayer   :music="{ title: ' ', src: item.content.url }" />
				</div>
			</div>
		</div>
</template>

<script>
import Aplayer from 'vue-aplayer'
export default {
  name: 'mixrow',
  components: {
    Aplayer
  },
  props: {
    desc: String
  },
  data() {
    return {
      theDesc: this.getMixrow(this.desc)
    };
  },
  methods: {
    getMixrow(mixrow) {
      if (mixrow != "" && mixrow != null) {
        mixrow = mixrow.replace(/\n/g, "\\n").replace(/\r/g, "\\r");
        mixrow = JSON.parse(mixrow);
		for (var n in mixrow) {
						if (mixrow[n].type == "text") {
							mixrow[n].content.text = mixrow[n].content.text.replace(/\n/g,"<br>");
						
					}
		}
        return mixrow;
      } else {
        return [];
      }
    },
  }
}
</script>

<style scoped lang="scss">
.mixrowItem {
		margin-bottom: 10px;
	}

	.text {
		line-height: 25px;
		word-wrap: break-word;
		color: #555555;
		font-size: 16px;
	}

	.image {
		max-width: 300px;
		display: block;
		overflow: hidden;
	}

	.mixrowImg {
		width: 100%;
	}

	.video {
		overflow: hidden;
		position: relative;
	}
	.videoThumbBox{
		width: 100%;
		height: 100%;
		position: relative;
	}
	.videoThumb {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	.playVideo {
		position: absolute;
		width: 40px;
		height: 40px;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
	}

	.videoPlayer {
		display: block;
		width: 100%;
		height: 100%;
	}
</style>
